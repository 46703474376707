
import '../styles/globals.css';
import '../styles/components/header.css';
import '../styles/components/footer.css';
import { useRouter } from "next/router";
import { useEffect } from "react";
import AOS from 'aos';
import Layout from '@/components/Layout/Layout';
import { Montserrat } from 'next/font/google';
const montserrat = Montserrat({
  subsets: ['latin'],
  display: 'swap',
})

function MyApp({ Component, pageProps }:any) {
  const router = useRouter();
  useEffect(() => {
    // Add any global initialization or routing logic here
    require('bootstrap/dist/js/bootstrap.min.js');
   
    AOS.init({
      duration: 1500
    });
   
  }, [router]);
  return (
    <>
      <style jsx global>{`
        html, body {
          font-family: ${montserrat.style.fontFamily};
          }
        `}
      </style>
        <Layout>
        
            <Component {...pageProps} />
        </Layout>

    </>
  );
}

export default MyApp;
