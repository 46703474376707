import React from 'react'
import dynamic from 'next/dynamic';

const Header = dynamic(() => import('../Header/Header'));
const Cta = dynamic(() => import('../Common/Cta'));
const Footer = dynamic(() => import('../Footer/Footer'));
const ScrollTop = dynamic(() => import('../Common/ScrollTop'));
const SectionScroll = dynamic(() => import('../Common/SectionScroll'));

interface LayoutProps {
  children: React.ReactNode;
}
function Layout({ children }: LayoutProps) {
  return (
    <>
      <Header />

      {children}
      <Cta/>
      <Footer/>
      <ScrollTop/>
      <SectionScroll/>
    </>
  )
}

export default Layout
